<template>
  <div
    ref="overlayEl"
    class="hb-dialog-overlay"
    :class="{
      'hb-dialog-overlay--close-by-overlay': closeByOverlay,
      'hb-dialog-overlay--centered': verticalCenter,
      'hb-dialog-overlay--padding': usePadding,
      'hb-dialog-overlay--transparent': isTransparent
    }"
    tabindex="1"
    @keydown.esc="onEsc"
    @click.self="onClick"
  >
    <!--    <div class="hb-dialog-overlay__head">-->
    <!--      <nuxt-icon name="logo" />-->
    <!--    </div>-->
    <div
      class="hb-dialog-overlay__body"
      :class="{
        'hb-dialog-overlay__body--show': showBody,
        'hb-dialog-overlay__body--max-height': !usePadding,
      }"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { disableDocumentScroll, enableDocumentScroll } from 'wue'
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { useRoute } from '#app'
import { useScroll } from '@vueuse/core'
import {
  chatToBackground,
  chatToDefault,
  getMainHeaderEl,
  removePaddingTopFromDocElement,
  setPaddingTopOnDocElement,
} from '~/utils'
import { provideDialog, useDM } from '~/composables'

export default {
  name: 'HbDialogOverlay',
  props: {
    usePadding: { type: Boolean, default: () => true },
    closeFn: { type: Function, default: () => {} },
    fixedBody: { type: Boolean, default: () => false },
    closeByEsc: { type: Boolean, default: () => false },
    closeByOverlay: { type: Boolean, default: () => false },
    verticalCenter: { type: Boolean, default: () => false },
    isTransparent: { type: Boolean, default: () => false},
  },
  setup(props) {
    const showBody = ref(false)
    const dm = useDM()
    const hasActions = ref(false)
    const overlayEl = ref<HTMLElement>(null)
    const scrollCb = ref<() => void>(null)
    const closeFn = (cb?: () => void) => {
      showBody.value = false

      if (props.closeFn) {
        setTimeout(() => {
          props.closeFn(cb)
        }, 150)
      }
    }
    const optimizeMainHeader = (fixed: boolean) => {
      const mainHeaderEl = getMainHeaderEl()
      if (mainHeaderEl) {
        if (fixed) {
          const scrollBarWidth =
            document.documentElement.style.getPropertyValue(
              '--wue-scrollbar-width'
            )
          mainHeaderEl.style.position = 'fixed'
          mainHeaderEl.style.width = scrollBarWidth
            ? `calc(100% - ${scrollBarWidth})`
            : '100%'
        } else {
          mainHeaderEl.style.removeProperty('position')
          mainHeaderEl.style.removeProperty('width')
        }
      }
    }
    const onEsc = () => {
      if (props.closeByEsc) {
        closeFn()
      }
    }
    const onClick = () => {
      if (props.closeByOverlay) {
        closeFn()
      }
    }

    useScroll(overlayEl, {
      onStop() {
        if (scrollCb.value) {
          scrollCb.value?.()
          scrollCb.value = null
        }
      },
    })

    provideDialog(
      computed(() => ({
        hasActions,
        scrollTo: (opts, cb = () => {}) => {
          if (overlayEl.value.scrollTop === 0) {
            cb?.()
          } else {
            overlayEl.value.scrollTo({ behavior: 'smooth', ...opts })
            scrollCb.value = cb
          }
        },
        close: closeFn,
      }))
    )

    onMounted(() => {
      showBody.value = true
      disableDocumentScroll()
      chatToBackground()
      optimizeMainHeader(!props.fixedBody)

      if (!props.fixedBody) {
        setPaddingTopOnDocElement()
      }

      if (props.closeByEsc) {
        overlayEl.value?.focus?.()
      }
    })
    onBeforeUnmount(() => {
      if (!dm.dialogs.value.length) {
        enableDocumentScroll()
        chatToDefault()
        optimizeMainHeader(false)

        if (!props.fixedBody) {
          removePaddingTopFromDocElement()
        }
      }
    })

    watch(
      () => useRoute().path,
      () => closeFn(),
      { flush: 'pre' }
    )

    return {
      showBody,
      overlayEl,
      onEsc,
      onClick,
    }
  },
}
</script>

<style lang="scss">
.hb-dialog-overlay {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  backdrop-filter: blur(4px);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background: var(--hb-white);
  overflow-y: auto;

  &--padding {
    background: rgba(#c2cdd1, 0.85);

    --hb-dialog-overlay-padding-x: 80px;

    padding: 40px var(--hb-dialog-overlay-padding-x) 80px;

    @include desktop {
      --hb-dialog-overlay-padding-x: 20px;

      padding: 80px var(--hb-dialog-overlay-padding-x);
    }

    @include mobile {
      padding-top: 20px;
    }
  }

  &--transparent {
    background: transparent !important;
    backdrop-filter: none;
  }

  &__head {
    margin-bottom: 40px;

    .nuxt-icon {
      font-size: 43px;
      color: var(--hb-white);
    }
  }

  &__body {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    opacity: 0;
    transform: translateY(-30px);
    transition: all 0.3s ease;

    &--show {
      opacity: 1;
      transform: translateY(0);
    }

    &--max-height {
      height: 100%;
      overflow-y: auto;
    }
  }

  &--close-by-overlay {
    .hb-dialog-overlay__body {
      pointer-events: none;
      touch-action: none;
      user-focus: none;
      user-select: none;

      * {
        pointer-events: auto;
        touch-action: auto;
        user-focus: auto;
        user-select: auto;
      }
    }
  }

  &--centered {
    justify-content: center;
  }

  @include mobile {
    &__head {
      margin-bottom: 20px;
    }
  }
}
</style>
