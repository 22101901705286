<template>
  <div
    ref="toastEl"
    :class="rootClasses"
    :style="rootStyles"
    v-on="rootListeners"
  >
    <div class="hb-toast__icon">
      <!--todo: change icon when theme is success-->
      <nuxt-icon name="info" filled />
    </div>
    <div class="hb-toast__text">{{ text }}</div>
    <a
      v-if="linkText"
      href
      class="hb-toast__link"
      @click.prevent="clickOnLink()"
    >
      {{ linkText }}
    </a>
    <a href class="hb-toast__close" @click.prevent="close()">
      <hb-close-icon :size="12" />
    </a>
  </div>
</template>

<script lang="ts">
import { useTimer } from 'wue'
import { computed, onMounted, ref } from 'vue'
import HbCloseIcon from '~/components/base/HbCloseIcon.vue'
import type { ToastTheme } from '~/types'

export default {
  name: 'HbToast',
  components: { HbCloseIcon },
  props: {
    text: { type: String, required: true },
    linkText: { type: String, default: () => null },
    linkCb: { type: Function, default: () => {} },
    timeout: { type: Number, default: () => 3500 },
    closeFn: { type: Function, required: true },
    theme: {
      type: String as () => ToastTheme,
      default: () => 'primary',
    },
  },
  setup(props) {
    const toastEl = ref<HTMLElement>(null)
    const top = ref<number>(null)
    const rootClasses = computed(() => ({
      'hb-toast': true,
      [`hb-toast--${props.theme}`]: true,
    }))
    const rootStyles = computed(() => ({
      top: top.value ? top.value + 'px' : undefined,
    }))
    const rootListeners = {
      mouseenter: () => timer.pause(),
      mouseleave: () => timer.resume(),
    }
    const timer = useTimer(() => close(), props.timeout, { immediate: false })
    const clickOnLink = () => {
      if (props.linkCb) {
        props.linkCb()
      }
    }
    const close = () => {
      timer.clear()
      top.value = toastEl.value?.getBoundingClientRect?.()?.top
      setTimeout(() => {
        props.closeFn()
      }, 0)
    }

    onMounted(() => {
      if (props.timeout) {
        timer.start()
      }
    })

    return {
      toastEl,
      rootClasses,
      rootStyles,
      rootListeners,
      clickOnLink,
      close,
    }
  },
}
</script>

<style scoped lang="scss">
.hb-toast {
  --hb-toast-bg-color: var(--hb-secondary);
  --hb-toast-icon-color: var(--hb-primary);

  padding: 25px 25px 25px 27px;
  border-radius: 5px;
  width: 300px;
  display: flex;
  align-items: center;
  background: var(--hb-toast-bg-color);
  pointer-events: auto;
  touch-action: auto;
  user-focus: auto;
  user-select: auto;

  &__icon {
    margin-right: 15px;
    color: var(--hb-toast-icon-color);
    font-size: 19px;
    line-height: 0;
  }

  &__text {
    color: var(--hb-white);
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 0;
    margin-right: 10px;
  }

  &__link {
    color: var(--hb-white);
    font-size: 16px;
    font-weight: 700;
    line-height: 1;
    letter-spacing: 0;
    margin-right: 10px;
  }

  &__close {
    margin-left: auto;
    color: var(--hb-gray2);
  }

  &--warning {
    --hb-toast-bg-color: var(--hb-primary);
    --hb-toast-icon-color: var(--hb-blue1);
  }

  &--success {
    --hb-toast-bg-color: var(--hb-green);
    --hb-toast-icon-color: var(--hb-white);
  }

  &--error {
    --hb-toast-bg-color: var(--hb-red1);
    --hb-toast-icon-color: var(--hb-white);
  }
}
</style>
