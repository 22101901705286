<template>
  <div v-if="cookieVisible" class="cookie-box">
    <div class="cookie-box__header">
      <div class="cookie-box__title">{{ $t('cookie_box.title') }}</div>
    </div>

    <div class="cookie-box__body">
      <p class="cookie-box__text" v-html="$t('cookie_box.message')" />
      <div class="cookie-box__actions">
        <hb-btn to="/cookie" size="block-lg" outline theme="dark">
          {{ $t('label.cookie_preferences') }}
        </hb-btn>
        <hb-btn size="lg" theme="secondary" @click="acceptCookie">
          {{ $t('label.ok') }}
        </hb-btn>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import HbBtn from '~/components/base/utils/HbBtn.vue'
import { ref, useAcceptCookiesCookie } from '#imports'

export default defineComponent({
  name: 'CookieBox',
  components: {
    HbBtn,
  },
  setup() {
    const acceptedCookie = useAcceptCookiesCookie()
    const cookieVisible = ref(!acceptedCookie.value)

    const acceptCookie = () => {
      acceptedCookie.value = true
      cookieVisible.value = false
    }

    return {
      cookieVisible,
      acceptCookie,
    }
  },
})
</script>

<style lang="scss">
.cookie-box {
  position: fixed;
  background-color: var(--hb-white);
  box-shadow: 0px 30px 60px #aab8be80;
  border-radius: 10px;
  padding: 26px 30px;
  width: 88%;
  max-width: var(--hb-container-max-width);
  display: flex;
  flex-direction: column;
  gap: 14px;
  bottom: 80px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;

  &__body {
    display: flex;
    gap: 40px;
    align-items: center;
    justify-content: space-between;

    @include mobile {
      flex-direction: column;
      gap: 20px;
    }
  }

  &__title {
    font-size: 16px;
    letter-spacing: 0;
    line-height: 20px;
    color: var(--hb-blue1);
  }

  &__text {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    letter-spacing: 0.14px;

    a {
      color: var(--hb-secondary);
      text-decoration: none;
      font-weight: 700;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: 15px;

    .hb-btn {
      font-size: 16px;

      &:first-child {
        width: 270px;
      }

      &:last-child {
        width: 170px;
      }

      @include desktop {
        &:first-child,
        &:last-child {
          width: 270px;
        }
      }

      @include mobile {
        &:first-child {
          width: 100%;
        }

        &:last-child {
          width: auto;
        }
      }
    }

    @include desktop {
      flex-direction: column;
    }

    @include mobile {
      width: 100%;
      flex-direction: row;
      align-items: stretch;
    }

    @media (max-width: 390px) {
      flex-direction: column;
    }
  }
}
</style>
