<template>
  <div :class="rootClasses" :style="rootStyles">
    <p>{{ text }}</p>
  </div>
</template>

<script lang="ts">
import { computed } from 'vue'
import { TooltipDirections } from '~/types'

export default {
  name: 'HbTooltip',
  props: {
    text: { type: String, default: () => '' },
    bottom: { type: Number, default: () => null },
    left: { type: Number, default: () => null },
    top: { type: Number, default: () => null },
    right: { type: Number, default: () => null },
    direction: {
      type: String as () => TooltipDirections,
      default: () => TooltipDirections.Top,
    },
    position: { type: String, default: () => 'absolute' },
    closeFn: { type: Function, required: true },
  },
  setup(props) {
    const rootStyles = computed(() => ({
      top: props.top ? props.top + 'px' : undefined,
      right: props.right ? props.right + 'px' : undefined,
      bottom: props.bottom ? props.bottom + 'px' : undefined,
      left: props.left ? props.left + 'px' : undefined,
      position: props.position ? props.position + ' !important' : undefined,
    }))
    const rootClasses = computed(() => ({
      'hb-tooltip': true,
      [`hb-tooltip--${props.direction}`]: true,
    }))

    return {
      rootClasses,
      rootStyles,
    }
  },
}
</script>

<style scoped lang="scss">
.hb-tooltip {
  $arrowSize: 20px;

  position: absolute;
  background: var(--hb-blue1);
  padding: 13px 15px;
  border-radius: 5px;
  box-shadow: 0 3px 30px #2b205166;
  max-width: 187px;
  width: max-content;
  z-index: 100;

  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    background: var(--hb-blue1);
    border-bottom-right-radius: 4px;
    width: $arrowSize;
    height: $arrowSize;
    z-index: -1;
  }

  &--top {
    transform: translate(-50%, calc($arrowSize * -1));

    &::after {
      bottom: calc(#{$arrowSize} / 2 * -1);
      left: 50%;
      transform: translateX(-50%) rotate(45deg);
    }
  }

  &--right {
    transform: translate($arrowSize, -50%);

    &::after {
      right: calc(100% - #{$arrowSize} / 2);
      top: 50%;
      transform: translateY(-50%) rotate(135deg);
    }
  }

  &--bottom {
    transform: translate(-50%, $arrowSize);

    &::after {
      bottom: calc(100% - #{$arrowSize} / 2);
      left: 50%;
      transform: translateX(-50%) rotate(-135deg);
    }
  }

  &--left {
    transform: translate(calc($arrowSize * -1), -50%);

    &::after {
      left: calc(100% - #{$arrowSize} / 2);
      top: 50%;
      transform: translateY(-50%) rotate(-45deg);
    }
  }

  p {
    color: var(--hb-white);
    font-size: 11px;
    line-height: 18px;
    font-weight: 500;
  }
}
</style>
