<template>
  <div class="mobile-popup" :class="{ 'mobile-popup--show': open }">
    <div class="mobile-popup__card">
      <div class="mobile-popup__top">
        <div class="mobile-popup__title">
          {{ title || $t('label.information') }}
        </div>
        <hb-raw-btn @click="closePopup()">
          <nuxt-icon name="close" filled></nuxt-icon>
        </hb-raw-btn>
      </div>
      <div class="mobile-popup__content">
        {{ text }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue'
import HbRawBtn from '~/components/base/utils/HbRawBtn.vue'

export default defineComponent({
  name: 'MobilePopup',
  components: { HbRawBtn },
  props: {
    text: { type: String, default: () => '' },
    title: { type: String, default: () => null },
    closeFn: { type: Function, required: true },
  },
  setup(props) {
    const open = ref(false)
    const closePopup = () => {
      open.value = false

      setTimeout(() => {
        props.closeFn?.()
      }, 200)
    }

    onMounted(() => {
      setTimeout(() => {
        open.value = true
      }, 0)
    })

    return {
      open,
      closePopup,
    }
  },
})
</script>

<style lang="scss">
.mobile-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;

  &__card {
    width: 100%;
    background-color: #fff;
    padding: 25px;
    border-radius: 16px;
    opacity: 0;
    transform: translateY(-30px);
    transition: all 0.3s ease;
  }

  &__top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  &__title {
    font-size: 18px;
    font-weight: 600;
  }

  &__content {
    max-height: 300px;
    overflow: auto;
    font-size: 14px;
    line-height: 26px;
  }

  &--show {
    .mobile-popup__card {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
</style>
