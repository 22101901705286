<template>
  <div class="hb-toast-container">
    <slot></slot>
  </div>
</template>

<script lang="ts">
export default {
  name: 'HbToastContainer',
}
</script>

<style scoped lang="scss">
.hb-toast-container {
  position: fixed;
  bottom: 0;
  right: 0;
  height: 100vh;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 15px;
  z-index: 100;
  pointer-events: none;
  touch-action: none;
  user-focus: none;
  user-select: none;
}
</style>
