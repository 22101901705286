<template>
  <div class="common-layout-container">
    <nuxt-loading-indicator color="var(--hb-primary)" />
    <div class="common-layout">
      <slot></slot>
    </div>
    <client-only>
      <transition-group name="fade">
        <hb-dialog-overlay
          v-for="(dialog, index) in dialogs"
          :key="index"
          :fixed-body="fixedBody"
          :close-fn="dialog.props.closeFn"
          :close-by-esc="dialog.props.closeByEsc"
          :vertical-center="dialog.props.verticalCenter"
          :close-by-overlay="dialog.props.closeByOverlay"
          :use-padding="dialog.props.usePadding"
          :is-transparent="dialog.props.isTransparent"
        >
          <component :is="dialog.component" v-bind="dialog.props" />
        </hb-dialog-overlay>
      </transition-group>

      <transition-group name="fade">
        <component
          :is="isMobile ? 'mobile-popup' : 'hb-tooltip'"
          v-for="(tooltip, index) in tooltips"
          :key="index"
          v-bind="tooltip.props"
        />
      </transition-group>

      <hb-toast-container>
        <transition-group name="fade-slide-right">
          <hb-toast
            v-for="toast in toasts"
            :key="toast.uuid"
            v-bind="toast.props"
          />
        </transition-group>
      </hb-toast-container>
      <cookie-box />
    </client-only>
  </div>
</template>

<script lang="ts">
import { useHead } from '#head'
import { useRuntimeConfig } from '#app'
import { isTrue } from 'wue'
import type { ReactiveHead } from '@unhead/vue'
import { onMounted } from 'vue'
import {
  useDialogsState,
  useToastsState,
  useTooltipsState,
  useViewTools,
} from '~/composables'
import HbDialogOverlay from '~/components/base/dialog/HbDialogOverlay.vue'
import HbTooltip from '~/components/base/utils/HbTooltip.vue'
import HbToastContainer from '~/components/base/utils/HbToastContainer.vue'
import HbToast from '~/components/base/utils/HbToast.vue'
import { showUnderDevelopment, useI18n } from '#imports'
import CookieBox from '~/components/CookieBox.vue'
import MobilePopup from '~/components/MobilePopup.vue'
import {
  FacebookPixelScript,
  getTitleTemplate,
  GTagScript,
  GTagScriptUrl,
  HotJarCode,
  LinkedinScript,
  LIVE_CHAT_NOSCRIPT_CODE,
  LIVE_CHAT_SCRIPT_CODE,
} from '~/constants'

export default {
  name: 'common',
  components: {
    MobilePopup,
    HbToast,
    HbToastContainer,
    HbTooltip,
    HbDialogOverlay,
    CookieBox,
  },
  props: {
    fixedBody: { type: Boolean, default: () => false },
  },
  setup() {
    const dialogs = useDialogsState()
    const tooltips = useTooltipsState()
    const toasts = useToastsState()
    const i18n = useI18n()
    const config = useRuntimeConfig()
    const trackingEnabled = isTrue(config.public.trackingEnabled)
    const scripts: ReactiveHead['script'] = []
    const isExist = (value: string | undefined) => value && value !== 'null'

    if (trackingEnabled) {
      if (
        isExist(config.public.hotJarId) &&
        isExist(config.public.hotJarSnippetVersion)
      ) {
        scripts.push({
          innerHTML: HotJarCode(
            config.public.hotJarId,
            config.public.hotJarSnippetVersion
          ),
          tagPosition: 'head',
        })
      }

      if (isExist(config.public.gTagId)) {
        scripts.push({
          async: true,
          src: GTagScriptUrl(config.public.gTagId),
        })
      }

      if (isExist(config.public.gTagId2)) {
        scripts.push({
          async: true,
          src: GTagScriptUrl(config.public.gTagId2),
        })
      }

      if (isExist(config.public.gTagId)) {
        scripts.push({
          innerHTML: GTagScript(config.public.gTagId, isExist(config.public.gTagId2) ? config.public.gTagId2 : null),
          tagPosition: 'head',
        });
      }

      if (isExist(config.public.linkedinPartnerId)) {
        scripts.push({
          innerHTML: LinkedinScript(config.public.linkedinPartnerId),
          tagPosition: 'head',
        })
      }

      if (isExist(config.public.facebookPixelId)) {
        scripts.push({
          innerHTML: FacebookPixelScript(config.public.facebookPixelId),
          tagPosition: 'head',
        })
      }
    }

    if (isExist(config.public.livechatId)) {
      scripts.push({
        innerHTML: LIVE_CHAT_SCRIPT_CODE(config.public.livechatId),
        tagPosition: 'bodyClose',
      })
    }

    useHead({
      htmlAttrs: {
        lang: i18n.locale.value,
        style: `--wue-scrollbar-width:4px;`,
      },
      script: scripts,
      noscript: [
        ...(isExist(config.public.livechatId)
          ? [
              {
                innerHTML: LIVE_CHAT_NOSCRIPT_CODE(config.public.livechatId),
                tagPosition: 'bodyClose',
              } as any,
            ]
          : []),
      ],
      titleTemplate: getTitleTemplate,
    })

    onMounted(() => {
      // setTimeout(() => showUnderDevelopment(), 0)
    })

    return {
      dialogs,
      tooltips,
      toasts,
      ...useViewTools(),
    }
  },
}
</script>

<style lang="scss">
.common-layout {
  min-height: 100vh;
}
</style>
